<script setup lang="ts">
import {
    getCategoryRoute,
    getCategoryUrl,
    getTranslatedProperty
} from "@shopware-pwa/helpers-next";
import {Category, Media} from "@shopware-pwa/types";

const {navigationElements} = useNavigation();
const menuHtmlElement = ref(null);
const menuContainer = ref<HTMLElement>();
const maxChildren = computed(() => {
    if (menuContainer.value?.parentNode?.parentNode?.clientWidth < 1400) {
        return navigationElements.value?.length ?? 1;
    }
    return 7;
});
const menuHeight = computed(() => maxChildren.value * 56);
const isSideMenuOpened = inject("isSideMenuOpened", ref(false));

onClickOutside(menuHtmlElement, e => {
    if (e.target && e.target.id == 'store-menu-button') {
        return;
    }
    const menuPosition = menuHtmlElement.value?.getBoundingClientRect()
    if (menuPosition && menuPosition.width > 0) {
        isSideMenuOpened.value = false
    }
});

const currentMenuPosition = ref<string | null>(null);

const elementHasChildren = (element: Category) => {
    return element.children && element.children.length > 0;
};

const splitChildren = (children: Category[]) => {
    const childrenArray = [];
    for (let i = 0; i < children.length; i += maxChildren.value) {
        childrenArray.push(children.slice(i, i + maxChildren.value));
    }
    return childrenArray;
};
const divmod = (x, y) => [Math.floor(x / y), x % y];
const showButton = (childLength:number, index:number) => {
    const val = divmod(childLength, maxChildren.value);
    if (val[1] == 0) {
        return false;
    }
    return val[0] == index;
}
const getItemImage = (media: Media|null) => {
    return media?.url ?? "/product_placeholder.webp";
};
</script>

<template>
<!-- eslint-disable vue/no-v-html -->
    <div class="fixed z-50">
        <Transition>
            <div
                v-if="isSideMenuOpened"
                class="relative hidden lg:block"
                role="dialog"
                aria-modal="true"
                ref="menuContainer"
                data-test-id="menu-container"
            >
                <div class="absolute top-[76px] w-fit h-fit inset-0 z-40 flex max-w-xs">
                    <div
                        ref="menuHtmlElement"
                        class="relative flex flex-col bg-white shadow-xl"
                    >
                        <div class="">
                            <div class="table">
                                <div
                                    class="table-cell"
                                    :class="{
                            'bg-brand-lightGold': currentMenuPosition
                          }"
                                >
                                    <ul class="flex w-[331px] flex-col p-0 space-y-0 list-none m-0 pt-5">
                                        <li
                                            v-for="navigationElement in navigationElements"
                                            :key="navigationElement.id"
                                        >
                                            <div
                                                class="text-sm cursor-pointer flex justify-between px-2 pb-[15px] inline-block w-full box-border"
                                                data-test-id="side-menu-first-level-with-children"
                                                @mouseover="currentMenuPosition = elementHasChildren(navigationElement) ? navigationElement.id : null"
                                            >
                                                <NuxtLink
                                                    :target="(navigationElement.externalLink || navigationElement.linkNewTab) ? '_blank' : ''"
                                                    :to="getCategoryRoute(navigationElement)"
                                                    @click="isSideMenuOpened = false"
                                                    class="font-medium text-[15px] hover-text-brand-primary pl-2 pr-4 w-9/12 flex items-center"
                                                    :class="{
                                            'text-brand-primary font-semibold': currentMenuPosition == navigationElement.id
                                        }"
                                                >
                                                    <div class="ml-2 w-[41px] flex justify-center">
                                                        <NuxtImg loading="lazy" :src="getItemImage(navigationElement.media)" height="41" />
                                                    </div>
                                                    <span class="ml-[16px]">{{ getTranslatedProperty(navigationElement, "name") }}</span>
                                                </NuxtLink>
                                                <span
                                                    v-if="elementHasChildren(navigationElement)"
                                                    class="flex items-center pr-[15px]"
                                                    :class="{
                                                      'text-brand-primary': currentMenuPosition == navigationElement.id,
                                                      'text-black': currentMenuPosition != navigationElement.id
                                                    }"
                                                >
                                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="9" class="mr-1" fill="currentColor" version="1.1" id="Layer_1" viewBox="0 0 330 330" xml:space="preserve">
                                          <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001  c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213  C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606  C255,161.018,253.42,157.202,250.606,154.389z"/>
                                      </svg>
                                    </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="table-cell" v-for="navigationElement in navigationElements" :key="navigationElement.id">
                                    <div
                                        v-if="currentMenuPosition === navigationElement.id && navigationElement?.children.length > 0"
                                        class="flex mx-4"
                                    >
                                        <template v-for="(childArray, index) in splitChildren(navigationElement.children)">
                                            <div class="w-[240px] xl:w-[300px] flex flex-col" :style="'height:' + menuHeight + 'px'">
                                                <template
                                                    v-for="(childElement, index) in childArray"
                                                    :key="childElement.id"
                                                >
                                                    <div :class="{ 'sm:pb-0': index !== navigationElement.children.length - 1 }"
                                                         class="bg-white p-0"
                                                    >
                                                        <NuxtLink
                                                            :to="getCategoryUrl(childElement)"
                                                            :target="(childElement.externalLink || childElement.linkNewTab) ? '_blank' : ''"
                                                            class="flex pb-[15px] font-medium text-[15px] hover-text-brand-primary items-center"
                                                            @click="isSideMenuOpened = false"
                                                        >
                                                            <div class="ml-2 w-[41px] flex justify-center">
                                                                <NuxtImg loading="lazy" :src="getItemImage(childElement.media)" height="41" />
                                                            </div>
                                                            <span class="ml-[16px]">{{ getTranslatedProperty(childElement, "name") }}</span>
                                                        </NuxtLink>
                                                    </div>
                                                </template>
                                                <div
                                                    v-if="showButton(navigationElement.children.length, index)"
                                                    class="bg-gray-100 p-0 w-[159px] mt-auto mb-[15px]"
                                                >
                                                    <NuxtLink
                                                        :to="getCategoryUrl(navigationElement)"
                                                        :target="(navigationElement.externalLink || navigationElement.linkNewTab) ? '_blank' : ''"
                                                        class="flex justify-between py-2 px-12 font-semibold h-[33px] text-sm hover-text-brand-primary"
                                                        @click="isSideMenuOpened = false"
                                                    >
                                                        {{ $t('general.seeAll') }}
                                                        <span class="flex items-center">
                                                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="11" height="11" class="mr-1" fill="currentColor" version="1.1" id="Layer_1" viewBox="0 0 330 330" xml:space="preserve">
                                                          <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001  c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213  C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606  C255,161.018,253.42,157.202,250.606,154.389z"/>
                                                      </svg>
                                                  </span>
                                                    </NuxtLink>
                                                </div>
                                            </div>
                                        </template>
                                        <div v-if="navigationElement.children.length % maxChildren == 0" class="flex flex-col w-52">
                                            <div class="relative bg-gray-100 w-[159px] p-0 mt-auto mb-[15px]">
                                                <NuxtLink
                                                    :to="getCategoryUrl(navigationElement)"
                                                    :target="(navigationElement.externalLink || navigationElement.linkNewTab) ? '_blank' : ''"
                                                    class="flex justify-between py-2 px-12 font-semibold h-[36px] text-sm hover-text-brand-primary"
                                                    @click="isSideMenuOpened = false"
                                                >
                                                    {{ $t('general.seeAll') }}
                                                    <span class="flex items-center">
                                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="11" height="11" class="mr-1" fill="currentColor" version="1.1" id="Layer_1" viewBox="0 0 330 330" xml:space="preserve">
                                                      <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001  c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213  C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606  C255,161.018,253.42,157.202,250.606,154.389z"/>
                                                  </svg>
                                              </span>
                                                </NuxtLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
